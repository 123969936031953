import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import {Link} from "gatsby"
import Layout from "../layout"

import Colorlist from "../colorlist"

import formatBOYSEN from "../../../lib/formatBoysen"

import * as styles from "./colorshelfpage.module.css"

const imgthecolorlibrarybanner = "../../images/colorlibrary/room.jpg"
const imgthecolorlibrarylogo = "../../images/colorlibrary/logo.png"

const nameoptions = [
	{"url":"colorlibrary", "title":"Shelf I"},
	{"url":"colorlibrary/shelf2", "title":"Shelf II"},
	{"url":"colorlibrary/shelf3", "title":"Shelf III"},
	{"url":"colorlibrary/shelf4", "title":"Shelf IV"},
	{"url":"colorlibrary/shelf5", "title":"Shelf V"},
]

const ColorshelfPage = ({activePath, colorList}) => {
	var tmpidx = 0;
	var pageTitle = nameoptions[0].title;

	while (tmpidx < nameoptions.length) {
		if (nameoptions[tmpidx].url === activePath) {
			pageTitle = nameoptions[tmpidx].title;
			break;
		}
		tmpidx++;
	}

	return (
		<Layout activePath={activePath} pageTitle={pageTitle+": The Color Library"} pageDescription={"The Color Lirbray "+pageTitle}>
			<StaticImage layout={"fullWidth"}  src={imgthecolorlibrarybanner} alt={"The Color Library"} />
			<div className="text-centered">
				<div className={styles.logoholder}>
					<StaticImage layout={"fullWidth"}  src={imgthecolorlibrarylogo} alt={"The Color Library"} />
				</div>
				<div className={styles.subtitle}>1320 Colors</div>
			</div>
			<div className={styles.optionsholder+" text-centered"}>
				{nameoptions.map((optionitem, optionidx)=> {
					return <>
						{optionidx>0&&<span>|</span>}
						<Link to={"/"+optionitem.url} className={styles.optionsitem+(optionitem.url===activePath?" "+styles.optionitemactive:"")}>
							{optionitem.title}
						</Link>
					</>
				})}
			</div>

			{<div className={styles.colorlist}>
				<Colorlist listobj={colorList} columntype={activePath=== "colorlibrary/shelf5"?"library4":"library"} listid={activePath} />
			</div>}
		</Layout>
	)
}

export default ColorshelfPage

